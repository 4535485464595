.section {
  margin-bottom: 20px;
  width: 45%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  /* margin-top: 30px; */
  margin-left: 5%;
  flex-direction: column;
}
.paper {
  /* background-color: grey; */
  /* //   boxShadow: \\; */
  padding: 10px;
  outline: none;
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
}
.sectionTitle {
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 0px;
}
.sectionContent {
  margin-bottom: 30px;
  font-size: 18px;
}
.TextField {
  width: 80%;
  margin-top: 20px !important;
  font-size: 18px !important;
}
.section.table {
  width: 100%;
  margin-left: 0;
}
.categoryModal {
  align-items: center;
  justify-content: center;
}
.upload-container {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  margin-top: 30px;
  width: 80%;
  justify-content: space-between;
}
/* .list-item{
  -webkit-box-shadow: 10px 10px 5px 0px rgba(240,240,240,1);
-moz-box-shadow: 10px 10px 5px 0px rgba(240,240,240,1);
box-shadow: 10px 10px 5px 0px rgba(240,240,240,1);
} */

.active {
  color: green;
  font-size: 12px;
  font-weight: 0;
}

.inactive {
  color: red;
  font-size: 12px;
  font-weight: 0;
}

.sub-heading {
  font-size: 15px;
  color: #ff6600;
  margin-top: 10px;
  margin-bottom: 0px;
}

.heading {
  font-size: 26px;
}

.eventInnerDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.eventTitle {
  font-size: 14px;
  font-weight: bold;
}

.css-1ruug1g-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1ruug1g-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1ruug1g-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  display: flex;
}

.MuiAutocomplete-popper {
  /* width: "400px" !important; */
  /* display: flex; */
}
